import styled from 'styled-components'
import { Media, spacing, typography } from '../../../common/theme'

export const NationalDayContainer = styled.div`
  max-width: 1400px;
  margin: auto;
  background-color: #00685a;
  ${Media.lessThan(Media.small)} {
    padding: 0 ${spacing.base};
  }
`

export const NationalDayTitle = styled.img`
  display: block;
  padding: 80px 0 0;
  margin: auto;
  max-width: 100%;
`

export const NationalDayJoinWayContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 80px;
  ${Media.lessThan(Media.small)} {
    flex-wrap: wrap;
  }
`

export const NationalDayJoinWayContent = styled.div`
  position: relative;
  height: 300px;
  width: 680px;
  border-radius: 20px;
  background: #007f6e;
  text-align: center;
  overflow: hidden;
  ${Media.lessThan(Media.small)} {
    height: 200px;
    max-width: 100%;
    margin-bottom: ${spacing.base};
  }
`

export const NationalDayJoinWayTitle = styled.h4`
  padding: ${spacing.large} 0 ${spacing.large} 40px;
  margin: 0;
  font-size: 30px;
  font-weight: bold;
  color: #fedc91;
  text-align: left;
  line-height: 1;
  ${Media.lessThan(Media.small)} {
    font-size: ${typography.h4};
  }
`

export const NationalDayJoinWayImage = styled.img`
  width: 550px;
  ${Media.lessThan(Media.small)} {
    max-width: 80%;
  }
`

export const NationalDayJoinWayInfo = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: ${spacing.base};
  font-size: ${typography.h4};
  color: white;
  p {
    width: 100%;
    margin: 0;
    min-width: 50px;
  }
  ${Media.lessThan(Media.small)} {
    margin-left: 0;
    font-size: ${typography.textSmall};
  }
`

export const NationalDayJoinWayTip = styled.div`
  position: absolute;
  top: 40px;
  right: -50px;
  transform: rotate(45deg);
  width: 200px;
  background: linear-gradient(90deg, #fc5518, #f5805d);
  font-size: ${typography.text};
  line-height: 24px;
  ${Media.lessThan(Media.small)} {
    font-size: ${typography.textSmall};
    top: 30px;
    right: -60px;
  }
`

export const NationalDayRulesContainer = styled.ul`
  padding: 50px 0 90px;
  margin: 0;
  color: white;
  list-style-type: none;
  font-size: ${typography.h4};
  line-height: 36px;
  ${Media.lessThan(Media.small)} {
    font-size: ${typography.text};
    line-height: 24px;
  }
`
